import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {ChevronDownIcon} from '@radix-ui/react-icons';
import {FunctionComponent, memo, useCallback, useState} from 'react';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import {ModelDropdownItem} from 'scout-chat/components/model-dropdown/ModelDropdownItem.tsx';
import {ChatModel} from 'scout-chat/types.ts';
import {twMerge} from 'tailwind-merge';
export interface ModelSelectFieldProps extends React.HTMLAttributes<HTMLButtonElement> {
  chatModels: ChatModel[];
  selectedModelId: string | undefined;
  setSelectedModelId: (selectedModelId: string | undefined) => void;
}

const ModelDropdown: FunctionComponent<ModelSelectFieldProps> = ({
  className,
  chatModels,
  selectedModelId,
  setSelectedModelId,
  ...props
}) => {
  const [openedTooltipId, setOpenedTooltipId] = useState<string | undefined>(undefined);

  const handleSetTooltipIsOpen = useCallback((id: string, isOpen: boolean) => {
    setOpenedTooltipId(currentValue => {
      if (!isOpen && currentValue === id) {
        return undefined;
      } else if (isOpen) {
        return id;
      }
      return currentValue;
    });
  }, []);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        {...props}
        className={twMerge(
          'flex items-center justify-between gap-2 px-4 py-3 w-fit text-base font-bold text-primary rounded-lg text-center truncate focus:outline-none bg-surface-02 hover:shadow transition-shadow shrink-0',
          className,
        )}
      >
        {chatModels.find(chatModel => chatModel.id === selectedModelId)?.displayName}
        <ChevronDownIcon />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className='w-[300px] border-stroke-main border-solid border bg-surface-02 p-2 rounded-xl shadow-lg text-primary z-30 max-h-[60vh] overflow-y-auto'
          sideOffset={5}
        >
          <DropdownMenu.RadioGroup value={selectedModelId} onValueChange={setSelectedModelId}>
            {chatModels.map((chatModel, index) => (
              <ScrollIntoViewIfNeeded
                active={chatModel.id === selectedModelId}
                options={{behavior: 'instant', block: 'start'}}
                key={chatModel.id}
              >
                <ModelDropdownItem
                  key={chatModel.id}
                  chatModel={chatModel}
                  tooltipIsOpen={openedTooltipId === chatModel.id}
                  toggleTooltipIsOpen={handleSetTooltipIsOpen}
                />
                {index !== chatModels.length - 1 && <DropdownMenu.Separator className='my-3 mx-2 h-px bg-surface-03' />}
              </ScrollIntoViewIfNeeded>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default memo(ModelDropdown);
