import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type UsageStatisticsResponse = {
  user_count: number;
  assistant_count: number;
  new_user_30: number;
  active_user_30: number;
  active_conversation_30: number;
};

export const fetchUsageStatistics = (): Promise<AxiosResponse<UsageStatisticsResponse>> => {
  return ScoutAPI.get<UsageStatisticsResponse>('/admin/statistics');
};
