import PlainNewConversationView from '#/components/chat-page/new-conversation/PlainNewConversationView.tsx';
import UseCasesView from '#/components/chat-page/new-conversation/UseCasesView';
import {useAppSettings} from '#/hooks/use-app-settings';
import {FunctionComponent, memo} from 'react';
import AssistantPresentation from 'scout-chat/components/AssistantPresentation';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';

export interface ConversationComponentProps {
  assistant?: AssistantPublicResponse;
  assistantQueryIsLoading: boolean;
  onStarterPromptSelect: (starter: string) => void;
  maxWidth?: string;
}

const NewConversationPresentation: FunctionComponent<ConversationComponentProps> = ({
  assistant,
  maxWidth,
  assistantQueryIsLoading,
  onStarterPromptSelect,
}) => {
  const {useCasesViewEnabled} = useAppSettings();

  return (
    <div className='size-full'>
      {assistantQueryIsLoading || assistant ? (
        <AssistantPresentation
          assistant={assistant}
          assistantIsLoading={assistantQueryIsLoading}
          onStarterPromptSelect={onStarterPromptSelect}
          maxWidth={maxWidth}
        />
      ) : useCasesViewEnabled ? (
        <UseCasesView maxWidth={maxWidth} />
      ) : (
        <PlainNewConversationView maxWidth={maxWidth} />
      )}
    </div>
  );
};

export default memo(NewConversationPresentation);
