import {ArrowUpIcon, Cross1Icon} from '@radix-ui/react-icons';
import {FormEvent, FunctionComponent, useCallback, useMemo, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import ScrollToBottom from 'react-scroll-to-bottom';
import TextareaAutosize from 'react-textarea-autosize';
import Alert from 'scout-chat/components/Alert.tsx';
import FilePicker from 'scout-chat/components/FilePicker.tsx';
import Files from 'scout-chat/components/Files.tsx';
import ScoutSpinner from 'scout-chat/components/ScoutSpinner.tsx';
import {ScrollToBottomButton} from 'scout-chat/components/ScrollToBottomButton.tsx';
import MentionAssistantSelect from 'scout-chat/components/mention-assistant/MentionAssistantSelect.tsx';
import {ConversationHeader} from 'scout-chat/components/scout-chat/ConversationHeader.tsx';
import MentionedAssistant from 'scout-chat/components/scout-chat/MentionedAssistant.tsx';
import Message from 'scout-chat/components/scout-chat/Message.tsx';
import {APP_NAME} from 'scout-chat/components/scout-chat/scout-chat-providers/ScoutChatContext.tsx';
import ScrollToBottomOnConversationChange from 'scout-chat/components/scout-chat/ux/ScrollToBottomOnConversationChange.tsx';
import ScrollToBottomOnNewUserMessage from 'scout-chat/components/scout-chat/ux/ScrollToBottomOnNewUserMessage.tsx';
import {useInputFiles} from 'scout-chat/hooks/logic/use-input-files.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {ConversationMessage, OnEditSubmitType} from 'scout-chat/types.ts';

import 'scout-chat/index.css';

export interface ConversationComponentCommonProps extends React.HTMLAttributes<HTMLDivElement> {
  maxWidth?: string;
  innerHeader?: React.ReactNode;
  outerHeader?: React.ReactNode;
  showNewConversationPresentation?: boolean;
  newConversationPresentation?: React.ReactNode;
  loadingSpinner?: React.ReactNode;
}

export interface ConversationComponentProps extends ConversationComponentCommonProps {
  messages: ConversationMessage[];
  conversationQueryIsLoading?: boolean;
  isLoading: boolean;
  error?: string;
  onSendMessageSubmit: () => void;
  message: string;
  onMessageChange: (value: string) => void;
  files?: File[];
  onFileChange?: (files: File[]) => void;
  assistant?: AssistantPublicResponse;
  promptInputRef?: React.RefObject<HTMLTextAreaElement>;
  mentionAssistantEnabled?: boolean;
  mentionedAssistant?: AssistantPublicResponse | null;
  onMentionedAssistantSelect?: (assistant: AssistantPublicResponse | null) => void;
  onRemoveMentionedAssistant?: () => void;
  conversationId?: string;
  onEditSubmit: OnEditSubmitType;
  showNewConversationPresentation?: boolean;
}

const ConversationComponent: FunctionComponent<ConversationComponentProps> = ({
  conversationId,
  messages,
  conversationQueryIsLoading = false,
  isLoading,
  error,
  onSendMessageSubmit,
  message,
  onMessageChange,
  assistant,
  files = [],
  onFileChange,
  promptInputRef,
  mentionAssistantEnabled,
  mentionedAssistant,
  onMentionedAssistantSelect,
  onRemoveMentionedAssistant,
  onEditSubmit,
  maxWidth,
  showNewConversationPresentation = false,
  children,
  innerHeader,
  outerHeader,
  newConversationPresentation,
  loadingSpinner = <ScoutSpinner />,
  ...props
}) => {
  const {t} = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const divToScrollRef = useRef<HTMLTableElement>(null);

  const assistantNameToDisplay = useMemo(() => {
    return assistant?.name ?? APP_NAME;
  }, [assistant?.name]);

  const talkingToAssistantName = useMemo(() => {
    return mentionedAssistant?.name || assistantNameToDisplay;
  }, [assistantNameToDisplay, mentionedAssistant?.name]);

  const [mentionAssistantSelectIsOpen, setMentionAssistantSelectIsOpen] = useState(false);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!(e.metaKey || e.ctrlKey || e.shiftKey) && e.key === 'Enter' && message.trim() !== '') {
        e.preventDefault();
        if (mentionAssistantSelectIsOpen) {
          return;
        }
        if (formRef && formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        }
      }
    },
    [mentionAssistantSelectIsOpen, message],
  );

  const conversationMessagesToShow = useMemo(() => {
    if (conversationQueryIsLoading) {
      return [];
    }
    return messages.filter(e => e.role !== 'system' && e.role !== 'tool' && e.content);
  }, [conversationQueryIsLoading, messages]);

  const {knowledgeFiles, handleAddFiles, handleRemoveFile} = useInputFiles(files, onFileChange);

  const {getRootProps, isDragActive} = useDropzone({onDrop: handleAddFiles, noClick: true});

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSendMessageSubmit();
    },
    [onSendMessageSubmit],
  );

  const handleOnMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onMessageChange(e.target.value);
    },
    [onMessageChange],
  );

  const handleOnMentionAssistantClose = useCallback(() => {
    promptInputRef?.current?.focus();
  }, [promptInputRef]);

  const scroller = useCallback(
    (values: {
      maxValue: number;
      minValue: number;
      offsetHeight: number;
      scrollHeight: number;
      scrollTop: number;
    }): number => {
      if (!isLoading && values.maxValue > 500) {
        return 0;
      }
      return Infinity;
    },
    [isLoading],
  );

  return (
    <div
      className='size-full bg-surface-01 relative flex flex-col text-primary grow overflow-hidden transition-opacity data-[isdragactive=true]:opacity-60 @container/scout-chat focus:outline-none'
      data-isdragactive={isDragActive}
      {...(handleAddFiles ? getRootProps() : {})}
      {...props}
    >
      <div
        className='absolute inset-0 z-30 rounded-2xl border-4 border-accent pointer-events-none opacity-0 outline-none transition-opacity data-[isdragactive=true]:opacity-100'
        data-isdragactive={isDragActive}
      />

      {outerHeader}

      <ScrollToBottom
        className='size-full relative grow overflow-auto @md/scout-chat:rounded-none'
        scrollViewClassName='w-full flex flex-col items-stretch overflow-x-hidden'
        followButtonClassName='hidden'
        scroller={scroller}
        mode='bottom'
      >
        <ScrollToBottomOnConversationChange
          conversationId={conversationId}
          conversationMessages={messages}
          conversationQueryIsLoading={conversationQueryIsLoading}
        />

        <ScrollToBottomOnNewUserMessage messages={messages} isLoading={isLoading} />

        <ConversationHeader>{innerHeader}</ConversationHeader>

        <div className='grow px-4' ref={divToScrollRef}>
          {showNewConversationPresentation ? (
            newConversationPresentation
          ) : (
            <div className='mx-auto mt-4 pb-6' style={{maxWidth}}>
              <div className='space-y-4 mx-auto @md/scout-chat:px-4'>
                {conversationMessagesToShow.map((conversationMessage, index) => (
                  <Message
                    key={index}
                    index={index}
                    message={conversationMessage}
                    conversationAssistantNameToDisplay={assistantNameToDisplay}
                    conversationAssistant={assistant}
                    conversationId={conversationId}
                    onEditSubmit={onEditSubmit}
                  />
                ))}
              </div>

              {isLoading && <div className='@md/scout-chat:pl-4'>{loadingSpinner}</div>}

              {error && <Alert variant='warning'>{error}</Alert>}
            </div>
          )}
        </div>

        <ScrollToBottomButton />

        {mentionAssistantEnabled && onMentionedAssistantSelect && (
          <MentionAssistantSelect
            isOpen={mentionAssistantSelectIsOpen}
            setIsOpen={setMentionAssistantSelectIsOpen}
            message={message}
            className='z-20 absolute inset-x-4 bottom-24 mx-auto'
            style={{maxWidth}}
            onClose={handleOnMentionAssistantClose}
            onAssistantSelect={onMentionedAssistantSelect}
          />
        )}

        <form className='w-full sticky gap-0 bottom-0 bg-transparent' onSubmit={handleSubmit} ref={formRef}>
          <div className='w-full h-8 bg-gradient-to-t from-surface-01' />

          <div className='w-full mx-auto bg-surface-01 @md/scout-chat:pb-6 pb-4 pt-2 px-4'>
            <div className='mx-auto bg-surface-03 rounded-2xl' style={{maxWidth}}>
              <Files
                files={knowledgeFiles}
                onRemoveFile={handleRemoveFile}
                className='mb-2 pt-2 max-h-[40vh] overflow-auto'
                filesClassName='bg-surface-02 rounded-md'
              />
              {mentionedAssistant && (
                <MentionedAssistant mentionedAssistant={mentionedAssistant} onRemove={onRemoveMentionedAssistant} />
              )}

              <div className='flex items-center w-full relative gap-3'>
                <TextareaAutosize
                  className='grow text-primary flex focus:outline-none focus:bg-surface-02 p-4 data-[supportsfiles=true]:pl-12 data-[supportsfiles=true]:@md/scout-chat:pl-[70px] pr-[45px] @md/scout-chat:pr-[64px] rounded-2xl border border-solid border-stroke-main resize-none bg-surface-01 text-lg'
                  value={message}
                  placeholder={t('prompt-placeholder', {assistant: talkingToAssistantName})}
                  onChange={handleOnMessageChange}
                  maxRows={10}
                  cols={10}
                  onKeyDown={handleKeyDown}
                  autoFocus
                  ref={promptInputRef}
                  data-mentionedassistant={!!mentionedAssistant}
                  data-supportsfiles={!!handleAddFiles}
                />

                {handleAddFiles && (
                  <div className='absolute left-2'>
                    <FilePicker
                      variant='icon'
                      textSize='base'
                      className='inline size-8 @md/scout-chat:p-1 p-1.5'
                      onFilesAdd={handleAddFiles}
                    />
                  </div>
                )}

                <div className='absolute right-2 @md/scout-chat:right-4'>
                  <button
                    className='w-8 aspect-square bg-accent flex justify-center items-center rounded-lg cursor-pointer hover:opacity-70 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed'
                    title={isLoading ? t('actions.cancel.hint') : t('actions.send.hint')}
                    disabled={message.trim() === '' && !isLoading}
                  >
                    {isLoading ? (
                      <Cross1Icon className='size-4 stroke-accent-inverse' />
                    ) : (
                      <ArrowUpIcon className='size-4 stroke-accent-inverse' />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ScrollToBottom>
    </div>
  );
};

export default ConversationComponent;
