import {Pencil1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, HTMLProps, memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import ConversationImg from 'scout-chat/components/ConversationImg.tsx';
import CopyToClipboardButton from 'scout-chat/components/CopyToClipboardButton.tsx';
import EditMessageButtonsView from 'scout-chat/components/EditMessageButtonsView.tsx';
import Files from 'scout-chat/components/Files.tsx';
import Markdown from 'scout-chat/components/Markdown.tsx';
import SpeakResponseAloudButton from 'scout-chat/components/SpeakResponseAloudButton.tsx';
import EditMessageTextarea from 'scout-chat/components/scout-chat/EditMessageTextarea.tsx';
import ToolsUsedView from 'scout-chat/components/tools-used/ToolsUsedView.tsx';
import {useAssistants} from 'scout-chat/hooks/contexts/use-assistants.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import useEditMessage from 'scout-chat/hooks/logic/use-edit-message.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {ConversationMessage, KnowledgeFile, OnEditSubmitType} from 'scout-chat/types.ts';
import {contentPartsToString} from 'scout-chat/utils/conversation-utils.ts';

const Message: FunctionComponent<
  {
    message: ConversationMessage;
    index: number;
    conversationAssistantNameToDisplay: string;
    conversationAssistant?: AssistantPublicResponse;
    conversationId: string | undefined;
    onEditSubmit: OnEditSubmitType;
  } & HTMLProps<HTMLDivElement>
> = ({
  message,
  index,
  conversationAssistantNameToDisplay,
  conversationAssistant,
  conversationId,
  onEditSubmit,
  ...props
}) => {
  const {t} = useTranslation();
  const {config} = useConfig();

  const {assistants} = useAssistants();

  const assistant = useMemo(() => {
    if (message.metadata?.assistant_id) {
      return assistants.find(assistant => assistant.id === message.metadata?.assistant_id);
    }
    return conversationAssistant;
  }, [assistants, conversationAssistant, message.metadata?.assistant_id]);

  const files: KnowledgeFile[] | undefined = useMemo(() => {
    return message.metadata?.files?.map(file => ({
      id: file.name,
      name: file.name,
      description: '',
      status: 'PROCESSED',
      contentType: file.content_type,
    }));
  }, [message.metadata?.files]);

  const assistantNameToDisplay = useMemo(() => {
    return assistant?.name || conversationAssistantNameToDisplay;
  }, [assistant?.name, conversationAssistantNameToDisplay]);

  const {
    isEditing,
    editedMessage,
    handleEditClick,
    onEditMessageChange,
    handleEditSubmit,
    handleEditCancel,
    editIsPending,
  } = useEditMessage(message, index, onEditSubmit);

  return (
    <div {...props} className='w-full relative group/message overflow-hidden' tabIndex={1}>
      <div className='relative flex gap-4 grow overflow-hidden w-full'>
        {message.role === 'assistant' && assistant ? (
          <ConversationImg
            role={message.role}
            assistant={assistant}
            containerClassName='size-6 shrink-0 mt-2.5'
            className='rounded-full shrink-0 text-base'
            loading='lazy'
            decoding='async'
          />
        ) : (
          <ConversationImg
            role={message.role}
            assistant={assistant}
            containerClassName='size-6 shrink-0 mt-2.5'
            className='rounded-full shrink-0 text-base'
            loading='lazy'
            decoding='async'
          />
        )}

        <div className='grow space-y-1 overflow-hidden'>
          <div className='align-top text-primary text-lg font-bold'>
            {message.role === 'user'
              ? t('user-title')
              : (assistant && assistantNameToDisplay) || assistantNameToDisplay}
          </div>

          <ToolsUsedView metadata={message.metadata} />

          {message.role === 'assistant' && typeof message.content === 'string' ? (
            <Markdown>{message.content}</Markdown>
          ) : Array.isArray(message.content) ? (
            <Markdown>{contentPartsToString(message.content)}</Markdown>
          ) : isEditing ? (
            <EditMessageTextarea editedMessage={editedMessage} onChange={onEditMessageChange} />
          ) : (
            <div className='break-words whitespace-pre-wrap text-primary'>{message.content}</div>
          )}

          {files && <Files files={files} className='flex gap-2' />}

          {isEditing ? (
            <EditMessageButtonsView isPending={editIsPending} onSubmit={handleEditSubmit} onCancel={handleEditCancel} />
          ) : (
            <div className='flex gap-2 md:invisible group-focus-within/message:visible md:group-hover/message:visible'>
              <CopyToClipboardButton content={message} />
              {message.role === 'user' && typeof message.content === 'string' && (
                <button className='size-6 rounded-lg' onClick={handleEditClick}>
                  <Pencil1Icon className='stroke-secondary hover:text-primary hover:stroke-primary' />
                </button>
              )}
              {message.role === 'assistant' &&
                typeof message.content === 'string' &&
                config.features.speech &&
                conversationId && (
                  <SpeakResponseAloudButton
                    className='size-6 rounded-lg'
                    conversationId={conversationId}
                    messageIndex={index}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Message);
