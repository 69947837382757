import {QueryClientContext} from '@tanstack/react-query';
import {cloneElement, FunctionComponent, useContext} from 'react';
import {AppQueryClientProvider} from 'scout-chat/hooks/contexts/AppQueryClientProvider.tsx';
import {AuthProvider} from 'scout-chat/hooks/contexts/auth/AuthProvider.tsx';
import {AuthContext} from 'scout-chat/hooks/contexts/auth/use-auth.tsx';
import {
  AssistantsContext,
  AssistantsProvider,
  NoAssistantsProvider,
} from 'scout-chat/hooks/contexts/use-assistants.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {ThemeContext, ThemeProvider} from 'scout-chat/hooks/contexts/use-theme.tsx';
import {ToastsContext, ToastsProvider} from 'scout-chat/hooks/contexts/use-toasts.tsx';

export const withScoutChatProviders = <T extends JSX.IntrinsicAttributes>(Component: FunctionComponent<T>) => {
  return (props: T) => {
    const providerElements = [];

    const {config} = useConfig();

    const existingAppQueryClientContext = useContext(QueryClientContext);
    if (!existingAppQueryClientContext) {
      providerElements.push(<AppQueryClientProvider />);
    }

    const existingThemeContext = useContext(ThemeContext);
    if (!existingThemeContext) {
      providerElements.push(<ThemeProvider />);
    }

    const existingToastsContext = useContext(ToastsContext);
    if (!existingToastsContext) {
      providerElements.push(<ToastsProvider />);
    }

    const existingAuthContext = useContext(AuthContext);
    if (!existingAuthContext) {
      providerElements.push(<AuthProvider />);
    }

    const existingAssistantsContext = useContext(AssistantsContext);
    if (!existingAssistantsContext) {
      if (config.features.assistants) {
        providerElements.push(<AssistantsProvider />);
      } else {
        providerElements.push(<NoAssistantsProvider />);
      }
    }

    return providerElements.reduceRight(
      (children, provider) => {
        return cloneElement(provider, {children});
      },
      <Component {...props} />,
    );
  };
};
