import {ReactNode} from 'react';

export const DEFAULT_LANGUAGE = 'en';

export type OnEditSubmitType = (messageIndex: number, editedMessage: string) => Promise<void>;

export interface MetadataToolCall {
  tool_name: string;
  arguments: string;
  output_metadata?: Record<string, never>;
}

export type FunctionPermission = {call_id?: string; function_name?: string; denied?: boolean};

export interface MessageMetadata {
  assistant_id?: string;
  files?: {name: string; content_type: string}[];
  tool_calls?: MetadataToolCall[];
  function_permissions?: FunctionPermission[];
}

export type ConversationMessageContentPartParam =
  | {
      type: 'text';
      text: string;
    }
  | {
      type: 'image';
      data: string;
      content_type: string;
    }
  | {
      type: 'audio';
      data: string;
      format: string;
    };

export interface ToolCallFunction {
  name: string;
  arguments: string;
}

export interface MessageToolCall {
  id: string;
  type: string;
  function: ToolCallFunction;
}

export type MessageRole = 'user' | 'assistant' | 'system' | 'tool';

export interface ConversationMessage {
  role: MessageRole;
  content?: string | ReactNode | ConversationMessageContentPartParam[];
  metadata?: MessageMetadata | null;
  tool_call_id?: string | null;
  tool_calls?: MessageToolCall[];
}

export type ChatModel = {
  id: string;
  description: string;
  displayName: string;
  tags: string[] | null;
};

export interface KnowledgeFile {
  id: string;
  name: string;
  description: string;
  status: FileStatus;
  contentType: string;
}

export type FileStatus = 'FAILED' | 'IN_QUEUE' | 'PROCESSED' | 'PROCESSING';

export interface WebappConfig {
  application: {
    colors: {
      accent: string;
      accentDark: string;
      accentInverse: string;
    };
    companyName: string;
    supportLinks?: SupportLink[];
    localizedSupportLinks: LocalizedLinks | null;
    userAgreement: UserAgreement | null;
    maxFileSizeInMb?: number;
  };
  features: {
    tasks: boolean;
    assistants: boolean;
    assistantSharing: AssistantSharing;
    imageGeneration: boolean;
    speech: boolean;
    remoteFiles: boolean;
    assistantFiles: boolean;
    externalServices: ExternalService[];
    useCases: ConfigUseCase[];
    realtime: boolean;
  };
  integrations: {
    authentication: {
      auth0?: BaseOAuthLogin;
      google?: GoogleLogin;
      okta?: BaseOAuthLogin;
      microsoft?: BaseOAuthLogin;
    };
  };
}

export interface SupportLink {
  title: string;
  url: string;
}

export interface LocalizedLink {
  title: string;
  url: string;
}

export interface LocalizedLinks {
  links: Record<string, LocalizedLink[]>;
}

export interface UserAgreement {
  text: Record<string, string>;
  links: Record<string, Record<string, LocalizedLink>>;
}

export interface GoogleLogin {
  clientId: string;
}

export interface BaseOAuthLogin {
  baseUrl: string;
  clientId: string;
}

export interface ExternalService {
  name: string;
  display_name: string;
  icon_url: string;
}

export interface UseCase {
  id: string;
}

export interface ConfigUseCase extends UseCase {
  id: string;
  fr: string;
  en: string;
}

export enum AssistantSharing {
  AdminOnly = 'adminOnly',
  Disabled = 'disabled',
  Enabled = 'enabled',
}
