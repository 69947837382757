import {FunctionComponent, useMemo} from 'react';
import {MetadataToolCall} from 'scout-chat/types.ts';
import {castJson} from 'scout-chat/utils/json-utils.ts';

export interface ExecuteSaasRequestsToolArguments {
  request: string;
}

const ExecuteSaasRequestsFunctionPermissionMessage: FunctionComponent<{
  toolCall: MetadataToolCall;
}> = ({toolCall}) => {
  const toolArguments = useMemo(() => {
    return castJson<ExecuteSaasRequestsToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <div className='text-center mx-auto flex items-center gap-1'>
      {toolArguments && <span>{toolArguments.request}</span>}
    </div>
  );
};

export default ExecuteSaasRequestsFunctionPermissionMessage;
