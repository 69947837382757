import {useCallback, useMemo} from 'react';
import {useValidateFileSize} from 'scout-chat/hooks/logic/use-validate-file-size.tsx';
import {KnowledgeFile} from 'scout-chat/types.ts';

export const useInputFiles = (files: File[], onFileChange: ((files: File[]) => void) | undefined) => {
  const validateFileSize = useValidateFileSize();

  const knowledgeFiles: KnowledgeFile[] = useMemo(() => {
    return files.map(file => ({
      id: file.name,
      name: file.name,
      description: '',
      status: 'PROCESSED',
      contentType: file.type,
    }));
  }, [files]);

  const handleAddFiles = useMemo(() => {
    if (!onFileChange) {
      return undefined;
    }

    return (newFiles: File[]) => {
      const validFiles = newFiles.filter(validateFileSize);

      const existingFileNames = new Set(files.map(file => file.name));
      const uniqueFiles = validFiles.filter(newFile => !existingFileNames.has(newFile.name));
      onFileChange?.([...files, ...uniqueFiles]);
    };
  }, [files, onFileChange, validateFileSize]);

  const handleRemoveFile = useCallback(
    (index: number) => {
      onFileChange?.(files.filter((_, i) => i !== index));
    },
    [files, onFileChange],
  );

  return {knowledgeFiles, handleAddFiles, handleRemoveFile};
};
