import {useMemo} from 'react';
import {TaskPlanExecutionStep} from 'scout-chat/repositories/task_plan_types';

const useCodeString = (selectedStep?: TaskPlanExecutionStep) => {
  return useMemo(() => {
    if (!selectedStep?.code_execution?.code) {
      return null;
    }

    return {
      markdown: `\`\`\`python\n${selectedStep?.code_execution?.code ?? ''}`,
      raw: selectedStep?.code_execution?.code,
    };
  }, [selectedStep?.code_execution?.code]);
};
export default useCodeString;
