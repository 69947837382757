import {FunctionComponent} from 'react';

export const ConversationHeader: FunctionComponent<{
  children?: React.ReactNode;
}> = ({children}) => {
  return (
    <div className='w-full gap-0 sticky top-0 left-0 bg-transparent z-10 mx-auto'>
      <div className='bg-surface-01'>{children}</div>
      <div className='block w-full h-8 bg-gradient-to-b from-surface-01' />
    </div>
  );
};
