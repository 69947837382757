import {createTask} from '#/repositories/assistants-api/requests/create-task';
import {createTaskSchedule} from '#/repositories/assistants-api/requests/create-task-schedule';
import {deleteTask} from '#/repositories/assistants-api/requests/delete-task';
import {deleteTaskSchedule} from '#/repositories/assistants-api/requests/delete-task-schedule';
import {duplicateTask} from '#/repositories/assistants-api/requests/duplicate-task.ts';
import {fetchAllTaskRuns} from '#/repositories/assistants-api/requests/fetch-all-task-runs';
import {TaskRunResponse, TaskRunStatus, fetchTaskRun} from '#/repositories/assistants-api/requests/fetch-task-run';
import {fetchTaskRuns} from '#/repositories/assistants-api/requests/fetch-task-runs';
import {fetchTaskSchedules} from '#/repositories/assistants-api/requests/fetch-task-schedules';
import {TaskResponse, fetchTask, fetchTasks} from '#/repositories/assistants-api/requests/fetch-tasks';
import {startTask} from '#/repositories/assistants-api/requests/start-task';
import {UpdateTaskRequest, updateTask} from '#/repositories/assistants-api/requests/update-task';
import {
  UpdateTaskCapabilityStepsRequest,
  updateTaskCapabilitySteps,
} from '#/repositories/assistants-api/requests/update-task-capability-step.ts';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {t} from 'i18next';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'scout-chat/hooks/contexts/auth/use-auth.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';
import {useAuthenticatedQuery} from 'scout-chat/hooks/requests/use-authenticated-query.tsx';

const TASK_POLLING_INTERVAL = 5000;

export const useTasksQuery = () =>
  useAuthenticatedQuery({
    queryKey: ['tasks'],
    queryFn: async () => {
      const response = await fetchTasks();
      return response.data;
    },
  });

type UseTaskQueryParams = {
  taskId?: string;
  shouldPoll: boolean;
  status?: TaskRunStatus;
};

type UseTaskRunQueryParams = UseTaskQueryParams & {
  runId?: string;
};

export const useTaskQuery = ({taskId, shouldPoll}: UseTaskQueryParams) =>
  useQuery({
    queryKey: ['task', taskId],
    queryFn: async () => {
      const response = await fetchTask(taskId || '');
      return response.data;
    },
    enabled: !!taskId,
    refetchInterval: shouldPoll ? TASK_POLLING_INTERVAL : false,
  });

export const useCachedTaskQuery = (taskId?: string) => {
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData<TaskResponse>(['task', taskId]);
  const {data} = useTaskQuery({taskId: cachedData ? undefined : taskId, shouldPoll: false});
  return cachedData || data;
};

export const useTaskRunsQuery = ({taskId, shouldPoll, status}: UseTaskQueryParams) =>
  useQuery({
    queryKey: ['task_runs', [...(taskId ? [taskId] : [])]],
    queryFn: async () => {
      const response = await (taskId ? fetchTaskRuns(taskId || '') : fetchAllTaskRuns(status));
      return response.data;
    },
    refetchInterval: shouldPoll ? TASK_POLLING_INTERVAL : false,
  });

export const useTaskRunQuery = ({taskId, runId, shouldPoll}: UseTaskRunQueryParams) =>
  useQuery({
    queryKey: ['task_run', taskId, runId],
    queryFn: async () => {
      const task_run = await fetchTaskRun(taskId || '', runId || '');
      return task_run;
    },
    enabled: !!taskId && !!runId,
    refetchInterval: shouldPoll ? TASK_POLLING_INTERVAL : false,
  });

export const useCreateTaskMutation = (onSuccess: (response: TaskResponse) => void) => {
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['create-task'],
    mutationFn: createTask,
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'tasks']});
      onSuccess(data);
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useUpdateTaskMutation = (onSuccess: (response: TaskResponse) => void) => {
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['update-task'],
    mutationFn: async (data: {taskId: string; editedTask: UpdateTaskRequest}) =>
      updateTask(data.taskId, data.editedTask),
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'tasks']});
      queryClient.invalidateQueries({queryKey: ['task', data.id]});
      onSuccess(data);
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useDeleteTaskMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {addToast} = useToasts();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['delete-task'],
    mutationFn: deleteTask,
    onSuccess: () => {
      addToast(t('create-edit-task.delete-confirmation.toasts.success'), 'success');
      navigate('/tasks');
      queryClient.invalidateQueries({queryKey: [user?.id, 'tasks']});
    },
  });
};

export const useStartTaskMutation = (onSuccess: (response: TaskRunResponse) => void) => {
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['start-task'],
    mutationFn: startTask,
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'tasks']});
      queryClient.invalidateQueries({queryKey: ['runs']});
      onSuccess(data);
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useUpdateTaskCapabilitySteps = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['update-task-capability-steps'],
    mutationFn: async (data: {taskId: string; capabilityId: string; request: UpdateTaskCapabilityStepsRequest}) =>
      updateTaskCapabilitySteps(data.taskId, data.capabilityId, data.request),
    onSuccess: ({data: updatedTask}) => {
      queryClient.invalidateQueries({queryKey: ['task', updatedTask.id]});
      addToast(t('create-edit-task.toasts.edit-success'), 'success');
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useDuplicateTaskMutation = () => {
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['duplicate-task'],
    mutationFn: duplicateTask,
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'tasks']});
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useTaskSchedulesQuery = ({taskId}: {taskId?: string}) =>
  useQuery({
    queryKey: ['task_schedules', taskId],
    queryFn: async () => {
      const response = await fetchTaskSchedules(taskId || '');
      return response.data;
    },
    enabled: !!taskId,
  });

export const useCreateTaskScheduleMutation = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['create-task-schedule'],
    mutationFn: createTaskSchedule,
    onSuccess: ({data}) => {
      addToast(t('create-edit-task.fields.schedules.create-confirmation.toasts.success'), 'success');
      queryClient.invalidateQueries({queryKey: ['task_schedules', data.task_id]});
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useDeleteTaskScheduleMutation = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();

  return useMutation({
    mutationKey: ['delete-task-schedule-mutation'],
    mutationFn: deleteTaskSchedule,
    onSuccess: () => {
      addToast(t('create-edit-task.fields.schedules.delete-confirmation.toasts.success'), 'success');
      queryClient.invalidateQueries({queryKey: ['tasks-schedules']});
    },
  });
};
