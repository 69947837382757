import {useUsageStatisticsQuery} from '#/hooks/query/statistics';
import Label from '#/library/label/Label.tsx';
import {Cross1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button.tsx';

export const UsageStatistics: FunctionComponent = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const statistics = useUsageStatisticsQuery();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className='flex flex-1 bg-black/[0.8] absolute inset-0 justify-center items-center z-20' onClick={handleClose}>
      <div
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
        className='flex-col space-y-6 min-w-[30%] p-8 bg-surface-01 md:border border-stroke-main md:rounded-2xl text-primary'
      >
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between'>
            <Label htmlFor='file-name'>{t('admin.statistics.user_count')}</Label>
            {statistics.data?.user_count}
          </div>
          <div className='flex justify-between'>
            <Label htmlFor='file-name'>{t('admin.statistics.assistant_count')}</Label>
            {statistics.data?.assistant_count}
          </div>
          <div className='flex justify-between'>
            <Label htmlFor='file-name'>{t('admin.statistics.active_users_30')}</Label>
            {statistics.data?.active_user_30}
          </div>
          <div className='flex justify-between'>
            <Label htmlFor='file-name'>{t('admin.statistics.new_users_30')}</Label>
            {statistics.data?.new_user_30}
          </div>
          <div className='flex justify-between'>
            <Label htmlFor='file-name'>{t('admin.statistics.active_conversations_30')}</Label>
            {statistics.data?.active_conversation_30}
          </div>
        </div>
        <div className='flex flex-row justify-end space-x-4'>
          <Button type='button' variant='secondary' size='sm' textSize='base' onClick={handleClose}>
            <Cross1Icon />
            {t('edit-file.close-button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
