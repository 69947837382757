import * as Accordion from '@radix-ui/react-accordion';
import {ChevronDownIcon} from '@radix-ui/react-icons';
import {FunctionComponent, HTMLAttributes, ReactNode, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MetadataToolCall} from 'scout-chat/types.ts';
import {StreamToolStatus} from 'scout-chat/utils/streaming-utils.ts';
import {twMerge} from 'tailwind-merge';

export interface FunctionPermissionDetailsComponent {
  (props: {toolCall: MetadataToolCall}): ReactNode;
}

export const detailsComponents: Record<string, FunctionPermissionDetailsComponent> = {};

const FunctionPermissionDetails: FunctionComponent<{toolCall: StreamToolStatus}> = ({toolCall}) => {
  return useMemo(() => {
    const Component = detailsComponents[toolCall.tool_name];
    return <Component toolCall={toolCall} />;
  }, [toolCall]);
};

export const FunctionPermissionDetailsWrapper: FunctionComponent<
  Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'>
> = ({children, className}) => {
  return <div className={twMerge('bg-surface-02 px-4 py-2 rounded-lg', className)}>{children}</div>;
};

const FunctionPermissionDetailsView: FunctionComponent<{toolCall: StreamToolStatus}> = ({toolCall}) => {
  const {t} = useTranslation();
  const [accordionValue, setAccordionValue] = useState('');
  if (!toolCall || !detailsComponents[toolCall.tool_name]) {
    return null;
  }

  return (
    <Accordion.Root
      className='w-full'
      type='single'
      collapsible
      value={accordionValue}
      onValueChange={setAccordionValue}
    >
      <Accordion.Item value='function-permission'>
        <Accordion.Header>
          <Accordion.Trigger className='w-full text-secondary opacity-70 hover:opacity-100 group/function-permission cursor-default outline-none'>
            <div className='mx-auto w-fit flex items-center gap-2 justify-between'>
              {accordionValue !== '' ? (
                <span>{t('function-permissions.hide-details')}</span>
              ) : (
                <span>{t('function-permissions.show-details')}</span>
              )}
              <ChevronDownIcon
                className='group-data-[state=open]/function-permission:rotate-180 transition-opacity duration-100 opacity-0 group-hover/function-permission:opacity-100 '
                aria-hidden
              />
            </div>
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content className='overflow-hidden data-[state=open]:animate-expandDown data-[state=closed]:animate-collapseUp '>
          <div className='overflow-hidden p-4 flex flex-col gap-2 rounded-lg border border-solid border-stroke-main'>
            <FunctionPermissionDetails key={toolCall.call_id} toolCall={toolCall} />
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};

export default FunctionPermissionDetailsView;
