import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {FunctionComponent, useRef} from 'react';

export const AppQueryClientProvider: FunctionComponent<Pick<React.HTMLProps<HTMLElement>, 'children'>> = ({
  children,
}) => {
  const queryClientRef = useRef<QueryClient>(new QueryClient());

  return <QueryClientProvider client={queryClientRef.current}>{children}</QueryClientProvider>;
};
