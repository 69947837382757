import {TaskRunStatus} from '#/repositories/assistants-api/requests/fetch-task-run';
import {ModelVisibilityResponse} from '#/repositories/assistants-api/requests/visibility';
import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {TaskPlanExecutionStep, TaskPlanStatusType, TaskStatusType} from 'scout-chat/repositories/task_plan_types';

export type TaskPlanResponse = {
  output_description: string;
  execution_plan: TaskPlanExecutionStep[];
  status: TaskPlanStatusType;
};

export type CapabilityResponse = {
  id: string;
  task_plan: TaskPlanResponse;
  description: string;
  input_variables: Record<string, never>;
};

export type ScheduleResponse = {
  crontab_expression: string;
  crontab_description: string;
};

export type TaskResponse = {
  id: string;
  description: string;
  instructions: string;
  status: TaskStatusType;
  capabilities: CapabilityResponse[];
  default_input_variables: Record<string, string>;
  visibility: ModelVisibilityResponse;
  runs_count: number;
  is_owner: boolean;
  owner_name: string;
  last_run_status?: TaskRunStatus;
  last_run_date?: string;
  schedule?: ScheduleResponse;
};

export type TaskExcerptResponse = {
  id: string;
  description: string;
  status: TaskStatusType;
  runs_count: number;
  last_run_status?: TaskRunStatus;
  last_run_date?: string;
};

export const fetchTasks = (): Promise<AxiosResponse<TaskExcerptResponse[]>> => {
  return ScoutAPI.get<TaskExcerptResponse[]>('/tasks');
};

export const fetchTask = (uuid: string): Promise<AxiosResponse<TaskResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<TaskResponse>>(`/tasks/${uuid}`);
};
