import {FunctionComponent, ReactNode, useMemo} from 'react';
import ExecuteSaasRequestsFunctionPermissionMessage from 'scout-chat/components/function-permissions/ExecuteSaasRequestsFunctionPermissionMessage';
import {MetadataToolCall} from 'scout-chat/types.ts';
import {StreamToolStatus} from 'scout-chat/utils/streaming-utils.ts';

export interface FunctionPermissionMessageComponent {
  (props: {toolCall: MetadataToolCall}): ReactNode;
}

const functionMessageComponents: Record<string, FunctionPermissionMessageComponent> = {
  execute_saas_requests: ExecuteSaasRequestsFunctionPermissionMessage,
};

const FunctionPermissionMessage: FunctionComponent<{toolCall: StreamToolStatus}> = ({toolCall}) => {
  return useMemo(() => {
    const Component = functionMessageComponents[toolCall.tool_name];
    return <Component toolCall={toolCall} />;
  }, [toolCall]);
};

const FunctionPermissionMessageView: FunctionComponent<{toolCall: StreamToolStatus}> = ({toolCall}) => {
  return <FunctionPermissionMessage toolCall={toolCall} />;
};

export default FunctionPermissionMessageView;
