import React, {createContext, useContext, useRef} from 'react';
import {useAllAssistantsQuery} from 'scout-chat/hooks/requests/use-all-assistants-query.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';

interface AssistantsContextType {
  assistants: AssistantPublicResponse[];
}

export const AssistantsContext = createContext<AssistantsContextType | undefined>(undefined);

export const useAssistants = () => {
  const context = useContext(AssistantsContext);
  if (context === undefined) {
    throw new Error('useAssistants must be used within a AssistantsProvider');
  }
  return context;
};

export const AssistantsProvider: React.FC<Pick<React.HTMLProps<HTMLElement>, 'children'>> = ({children}) => {
  const assistantsQuery = useAllAssistantsQuery();

  const contextValue = {
    assistants: assistantsQuery.data || [],
  };

  return <AssistantsContext.Provider value={contextValue}>{children}</AssistantsContext.Provider>;
};

export const NoAssistantsProvider: React.FC<Pick<React.HTMLProps<HTMLElement>, 'children'>> = ({children}) => {
  const contextValue = useRef<AssistantsContextType>({
    assistants: [],
  });

  return <AssistantsContext.Provider value={contextValue.current}>{children}</AssistantsContext.Provider>;
};
