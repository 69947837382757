import {FunctionComponent, useContext} from 'react';
import {ConfigurationContext, ConfigurationProvider} from 'scout-chat/hooks/contexts/use-config.tsx';

export const withScoutConfigProvider = <T extends JSX.IntrinsicAttributes>(Component: FunctionComponent<T>) => {
  return ({
    baseUrl,
    ...props
  }: T & {
    baseUrl?: string;
  }) => {
    const existingConfigurationContext = useContext(ConfigurationContext);

    if (!existingConfigurationContext) {
      return (
        <ConfigurationProvider baseUrl={baseUrl}>
          <Component {...(props as T)} />
        </ConfigurationProvider>
      );
    }

    return <Component {...(props as T)} />;
  };
};
