import {FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MENTION_ASSISTANT_REGEX} from 'scout-chat/components/mention-assistant/MentionAssistantSelect.tsx';
import {ScoutChatContext} from 'scout-chat/components/scout-chat/scout-chat-providers/ScoutChatContext.tsx';
import {withScoutChatProviders} from 'scout-chat/components/scout-chat/scout-chat-providers/hoc/withScoutChatProviders.tsx';
import {withScoutConfigProvider} from 'scout-chat/components/scout-chat/scout-chat-providers/hoc/withScoutConfigProvider.tsx';
import {useSetFormStateOnConversation} from 'scout-chat/components/scout-chat/scout-chat-providers/hooks/use-set-form-state-on-conversation';
import useSetSelectedModelId, {
  OnSelectedModelIdChange,
} from 'scout-chat/components/scout-chat/scout-chat-providers/hooks/use-set-selected-model-id';
import {useSetSelectedModelWhenNotRecognized} from 'scout-chat/components/scout-chat/scout-chat-providers/hooks/use-set-selected-model-when-not-recognized';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useDefaultModelId} from 'scout-chat/hooks/logic/use-default-model-id.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';
import {usePromptInputDataToShow} from 'scout-chat/hooks/logic/use-prompt-input-data-to-show.tsx';
import {useSetMessagesOnStreamChunks} from 'scout-chat/hooks/logic/use-set-messages-on-stream-chunks.tsx';
import {useChatModelsQuery} from 'scout-chat/hooks/requests/use-chat-models-query.tsx';
import {useConversationQuery} from 'scout-chat/hooks/requests/use-conversation-query.tsx';
import {
  OnConversationCreateSuccess,
  useCreateConversationMutation,
} from 'scout-chat/hooks/requests/use-create-conversation-mutation.tsx';
import {usePublicAssistantQuery} from 'scout-chat/hooks/requests/use-public-assistant-query.tsx';
import {useUpdateConversationMessageMutation} from 'scout-chat/hooks/requests/use-update-conversation-message-mutation.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {
  StreamConversationCompletionRequest,
  streamConversationCompletion,
} from 'scout-chat/requests/stream-conversation-completion.ts';
import {ConversationMessage, FunctionPermission, OnEditSubmitType} from 'scout-chat/types.ts';
import {getTimeZoneOffset} from 'scout-chat/utils/time-utils.ts';

export interface ConversationScoutChatProviderProps extends JSX.IntrinsicAttributes {
  children: ReactNode;
  conversationId?: string;
  assistantId?: string;
  mentionAssistantEnabled?: boolean;
  onCreateConversationSuccess?: OnConversationCreateSuccess;
  onSelectedModelIdChange?: OnSelectedModelIdChange;
  onStopStreaming?: () => void;
  language: string;
}

const ConversationScoutChatProvider: FunctionComponent<ConversationScoutChatProviderProps> = ({
  children,
  conversationId,
  assistantId,
  mentionAssistantEnabled = false,
  onCreateConversationSuccess,
  onSelectedModelIdChange,
  onStopStreaming,
  language,
}) => {
  const {addToast} = useToasts();
  const {
    t,
    i18n: {changeLanguage},
  } = useTranslation();

  useEffect(() => {
    changeLanguage(language);
  }, [changeLanguage, language]);

  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState<ConversationMessage[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState('');
  const abortControllerRef = useRef<AbortController>();

  const promptInputRef = useRef<HTMLTextAreaElement>(null);

  const createConversationMutation = useCreateConversationMutation({onSuccess: onCreateConversationSuccess});
  const loading = createConversationMutation.isPending || isStreaming;

  const conversationQuery = useConversationQuery({conversationId});
  useSetFormStateOnConversation(conversationQuery, setMessages, setIsStreaming, setError, abortControllerRef);

  const {defaultModelId, setDefaultModelId} = useDefaultModelId();
  const chatModels = useChatModelsQuery();
  const selectedModelId = conversationQuery.data?.model || defaultModelId;

  const setSelectedModelId = useSetSelectedModelId(setDefaultModelId, onSelectedModelIdChange);
  useSetSelectedModelWhenNotRecognized(chatModels, selectedModelId, setSelectedModelId);

  const publicAssistantQuery = usePublicAssistantQuery({assistantId});
  const assistant = conversationQuery.data?.assistant || publicAssistantQuery.data;
  const assistantQueryIsLoading = publicAssistantQuery.isLoading;

  const [mentionedAssistant, setMentionedAssistant] = useState<AssistantPublicResponse | null>(null);

  const handleMentionedAssistantSelect = useCallback(
    (assistant: AssistantPublicResponse | null) => {
      setMentionedAssistant(assistant);
      setPrompt(prompt.replace(MENTION_ASSISTANT_REGEX, ''));
    },
    [prompt],
  );

  const handleRemoveMentionedAssistant = useCallback(() => {
    setMentionedAssistant(null);
  }, []);

  const {promptToShow, filesToShow, mentionedAssistantToShow, sendMessageIsPending, setSendMessageIsPending} =
    usePromptInputDataToShow(createConversationMutation.isError, prompt, files, mentionedAssistant);

  const handleStopStreaming = useCallback(() => {
    setIsStreaming(false);
    onStopStreaming?.();
  }, [onStopStreaming]);

  const setMessagesOnStreamChunks = useSetMessagesOnStreamChunks(setMessages);

  const {convertErrorMessageToUserMessage} = useErrorMessage();

  const handleStreamChatCompletion = useCallback(
    async (
      newMessages: ConversationMessage[] | null,
      conversation: ConversationMessage[],
      functionPermissions?: FunctionPermission[],
    ) => {
      setIsStreaming(true);
      setSendMessageIsPending(true);

      const request: StreamConversationCompletionRequest = {
        new_messages: newMessages,
        model: selectedModelId || chatModels[0]?.id,
        mentioned_assistant_id: mentionedAssistant?.id,
        function_permissions: functionPermissions,
      };

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      try {
        await streamConversationCompletion(
          conversationId || '',
          request,
          chunks =>
            setMessagesOnStreamChunks(chunks, conversation, (functionPermissions, conversation) =>
              handleStreamChatCompletion(null, conversation, functionPermissions),
            ),
          files,
          error => {
            if (!abortControllerRef.current?.signal.aborted) {
              setError(convertErrorMessageToUserMessage(error));
              setIsStreaming(false);
            }
            throw error;
          },
          () => {
            handleStopStreaming();
          },
          abortController.signal,
        );
      } finally {
        setSendMessageIsPending(false);
      }
      setPrompt('');
      setFiles([]);
      setMentionedAssistant(null);
    },
    [
      setSendMessageIsPending,
      selectedModelId,
      chatModels,
      mentionedAssistant?.id,
      conversationId,
      files,
      setMessagesOnStreamChunks,
      convertErrorMessageToUserMessage,
      handleStopStreaming,
    ],
  );

  const sendMessage = useCallback(
    async (newMessages: ConversationMessage[], title: string | undefined = undefined) => {
      setIsStreaming(true);
      setSendMessageIsPending(true);

      const newConversation = [...messages, ...newMessages];

      setError('');
      if (conversationId === undefined) {
        createConversationMutation.mutate({
          title,
          time_zone_offset: getTimeZoneOffset(),
          payload: newConversation,
          assistant_id: assistantId,
          model: selectedModelId || '',
        });
        return;
      }

      handleStreamChatCompletion(newMessages, newConversation);
      setMessages(newConversation);
    },
    [
      setSendMessageIsPending,
      messages,
      conversationId,
      handleStreamChatCompletion,
      createConversationMutation,
      assistantId,
      selectedModelId,
    ],
  );

  const onSendMessageSubmit = useCallback(async () => {
    if (isStreaming) {
      abortControllerRef.current?.abort();
      handleStopStreaming();
      return;
    }

    const newMessage: ConversationMessage = {
      role: 'user',
      content: prompt,
      metadata: {
        files: files.map(file => ({name: file.name, content_type: file.type})),
      },
    };

    await sendMessage([newMessage]);
  }, [files, handleStopStreaming, isStreaming, prompt, sendMessage]);

  const updateConversationMessageMutation = useUpdateConversationMessageMutation();
  const {mutateAsync: updateConversationMessageMutateAsync} = updateConversationMessageMutation;
  const handleEditSubmit: OnEditSubmitType = useCallback(
    async (messageIndex, editedMessage) => {
      if (!conversationId) {
        addToast(t('toasts.missing-conversation-id'), 'error');
        return;
      }

      const response = await updateConversationMessageMutateAsync({
        conversationId: conversationId,
        request: {
          message: {
            role: 'user',
            content: editedMessage,
          },
          user_message_index: messageIndex,
        },
      });

      if (!response.data) {
        return;
      }
      const updatedMessages = [...response.data.payload];
      setMessages(updatedMessages);
      handleStreamChatCompletion(null, updatedMessages);
    },
    [addToast, conversationId, handleStreamChatCompletion, t, updateConversationMessageMutateAsync],
  );

  return (
    <ScoutChatContext.Provider
      value={{
        prompt: promptToShow,
        setPrompt,
        onSendMessageSubmit,
        sendMessage,
        handleStreamChatCompletion,
        sendMessageIsPending,
        messages,
        conversationQuery,
        conversationId,
        loading,
        chatModels,
        selectedModelId,
        setSelectedModelId,
        filesToShow,
        setFiles,
        error,
        assistant,
        assistantQueryIsLoading,
        mentionAssistantEnabled,
        mentionedAssistantToShow,
        handleMentionedAssistantSelect,
        handleRemoveMentionedAssistant,
        promptInputRef,
        handleEditSubmit,
      }}
    >
      {children}
    </ScoutChatContext.Provider>
  );
};

export default withScoutConfigProvider<ConversationScoutChatProviderProps>(
  withScoutChatProviders(ConversationScoutChatProvider),
);
