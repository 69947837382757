import {Cross2Icon, FileIcon, Pencil1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, memo, useCallback} from 'react';
import {Flipped, Flipper} from 'react-flip-toolkit';
import ScoutSpinner from 'scout-chat/components/ScoutSpinner.tsx';
import {ReactComponent as AlertIcon} from 'scout-chat/resources/alert-icon.svg';
import {FileStatus, KnowledgeFile} from 'scout-chat/types.ts';

interface FilesProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
  files?: KnowledgeFile[];
  onEditFile?: (index: number) => void;
  onRemoveFile?: (index: number) => void;
  filesClassName?: string;
}

const Files: FunctionComponent<FilesProps> = ({files, onEditFile, onRemoveFile, className, filesClassName}) => {
  const onEditClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
      e.preventDefault();
      onEditFile?.(index);
    },
    [onEditFile],
  );

  const onRemoveClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
      e.preventDefault();
      onRemoveFile?.(index);
    },
    [onRemoveFile],
  );

  return files?.length ? (
    <Flipper flipKey={files.map(file => file.id).join('')}>
      <div className={`flex gap-2 flex-wrap ${className}`}>
        {files.map((file, index) => (
          <Flipped key={file.id} flipId={file.id}>
            <div
              key={file.id}
              className={`rounded-xl border-2 bg-surface-01 border-solid border-stroke-main text-sm ${filesClassName || ''}`}
            >
              <div className='w-60 px-4 py-3 flex items-center justify-between gap-2'>
                <div className='flex overflow-hidden gap-2'>
                  <div className='flex relative items-center justify-center size-10 shrink-0 overflow-hidden rounded-lg bg-surface-02'>
                    <Icon status={file.status} />
                  </div>

                  <div>
                    <div className={`truncate font-bold ${file.status === 'FAILED' ? 'text-danger' : ''}`}>
                      {file.name}
                    </div>
                    <div className={`truncate ${file.status === 'FAILED' ? 'text-danger' : 'text-gray-400'}`}>
                      {file.contentType}
                    </div>
                  </div>
                </div>

                {onEditFile && (
                  <button onClick={e => onEditClick(e, index)} className='shrink-0'>
                    <span className='' data-state='closed'>
                      <Pencil1Icon className='size-5 transition-transform hover:scale-110' />
                    </span>
                  </button>
                )}

                {onRemoveFile && (
                  <button onClick={e => onRemoveClick(e, index)} className='shrink-0'>
                    <span className='' data-state='closed'>
                      <Cross2Icon className='size-5 transition-transform hover:scale-110' />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </Flipped>
        ))}
      </div>
    </Flipper>
  ) : null;
};

const Icon: FunctionComponent<{status: FileStatus}> = memo(({status}) => {
  if (status === 'PROCESSED') {
    return <FileIcon className='size-5' />;
  }
  if (status === 'PROCESSING' || status === 'IN_QUEUE') {
    return (
      <div className='size-full flex items-center justify-center'>
        <ScoutSpinner />
      </div>
    );
  }
  if (status === 'FAILED') {
    return <AlertIcon className='size-5 stroke-danger' />;
  }
});

export default memo(Files);
