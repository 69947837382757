import {useMemo} from 'react';
import {TaskPlanExecutionStep} from 'scout-chat/repositories/task_plan_types';

const useOriginalSteps = (executionPlan?: TaskPlanExecutionStep[]) => {
  return useMemo(() => {
    return (
      executionPlan?.map(step => ({
        prompt: step.prompt || '',
        code: step.code_execution?.code || '',
      })) ?? []
    );
  }, [executionPlan]);
};
export default useOriginalSteps;
