import {useTranslation} from 'react-i18next';
import {DefaultLoader} from 'scout-chat/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from 'scout-chat/components/tool-loaders/ToolStatusLoader.tsx';
import {castJson} from 'scout-chat/utils/json-utils';

export interface ExecuteSaasRequestsArguments {
  request: string;
}

const ExecuteSaasRequestsToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  const toolArguments = castJson<ExecuteSaasRequestsArguments>(props.arguments);

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <span>
        {toolArguments ? <span>{toolArguments.request}</span> : t('tools.execute-python-code.in-progress-text')}
      </span>
    </div>
  );
};

export default ExecuteSaasRequestsToolLoader;
