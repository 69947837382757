import {RealtimeClient} from '@openai/realtime-api-beta';
import {Cross2Icon} from '@radix-ui/react-icons';
import {useQueryClient} from '@tanstack/react-query';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button';
import {useAuth} from 'scout-chat/hooks';
import environment from 'scout-chat/repositories/environment';
import {getAccessToken} from 'scout-chat/repositories/tokens_repository';
import {ReactComponent as ScoutLogo} from 'src/resources/logo.svg';
import {WavRecorder, WavStreamPlayer} from 'wavtools';
import {DecodedAudioType} from 'wavtools/dist/lib/wav_recorder';

type RealtimePageParams = {
  conversationId: string;
};

export const RealtimePage: FunctionComponent = () => {
  const navigate = useNavigate();
  const {conversationId} = useParams<RealtimePageParams>();
  const access_token = getAccessToken();

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({sampleRate: 24000}));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({sampleRate: 24000}));
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({url: `${environment.baseApiUrl}/realtime/${access_token}/${conversationId}`}),
  );

  const [, setCurrentResponseId] = useState<string>();
  const [currentAnswer, setCurrentAnswer] = useState<string>('');
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  interface RealtimeEvent {
    time: string;
    source: 'client' | 'server';
    count?: number;
    event: {type: string; delta: string; response_id: string};
  }

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();

    // Always stream the audio (server_vad mode)
    await wavRecorder.record(data => client.appendInputAudio(data.mono));
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    //client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const {trackId, offset} = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on(
      'conversation.updated',
      async ({
        item,
        delta,
      }: {
        item: {status: string; id: string; formatted: {audio?: Blob; file: DecodedAudioType}};
        delta: {audio: ArrayBuffer};
      }) => {
        if (delta?.audio) {
          wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        if (item.status === 'completed' && item.formatted.audio?.size) {
          const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000);
          item.formatted.file = wavFile;
        }
      },
    );

    connectConversation();

    return () => {
      // cleanup; resets to defaults
      disconnectConversation();
      client.reset();
    };
  }, [connectConversation, disconnectConversation]);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const callback = (realtimeEvent: RealtimeEvent) => {
      if (realtimeEvent.event['type'] === 'response.audio_transcript.delta') {
        const delta = realtimeEvent.event['delta'];
        const responseId = realtimeEvent.event['response_id'];

        setCurrentResponseId(currentResponseId => {
          setCurrentAnswer(currentAnswer => {
            if (currentResponseId === responseId) {
              return currentAnswer + delta;
            } else {
              return delta;
            }
          });
          return responseId;
        });
      }
      setIsAnimating(!!wavStreamPlayer.getFrequencies('voice')['values'].find(a => a > 0));
    };
    clientRef.current.on('realtime.event', callback);
  }, [setCurrentAnswer, setCurrentResponseId, wavStreamPlayerRef]);

  const queryClient = useQueryClient();
  const {user} = useAuth();

  const handleClose = useCallback(() => {
    setTimeout(() => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'conversations']});
      queryClient.invalidateQueries({queryKey: [user?.id, 'conversation', conversationId]});
    }, 1000);
    navigate(`/chat/${conversationId}`);
  }, [navigate, queryClient, user, conversationId]);

  return (
    <div
      className='flex flex-1 bg-black/[0.8] absolute inset-0 justify-center items-center z-20 '
      onClick={handleClose}
    >
      <div className='absolute z-20 top-4 right-4'>
        <Button onClick={handleClose} type='button' variant='secondary' size='sm' textSize='base' className='h-12'>
          <Cross2Icon className='size-5 transition-transform hover:scale-110' />
        </Button>
      </div>

      <div
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
        className='flex flex-col justify-between space-y-6 w-4/5 md:w-2/5 h-1/2 p-8 text-white'
      >
        <div className={'flex justify-center ' + (isAnimating ? 'animate-pulse' : '')}>
          <ScoutLogo className='md:size-40 size-20 fill-accent ' />
        </div>
        <div className='md:text-lg font-semibold'>{currentAnswer}</div>
      </div>
    </div>
  );
};
