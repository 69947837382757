import {CodeIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import PythonCodeMarkdown from 'scout-chat/components/tools-used/PythonCodeMarkdown';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {useExternalService} from 'scout-chat/hooks/logic/use-external-service';
import {TaskPlanExecutionStep} from 'scout-chat/repositories/task_plan_types';
import {castJson} from 'scout-chat/utils/json-utils.ts';
import {twMerge} from 'tailwind-merge';

interface ExecuteSaasRequestsToolArguments {
  request: string;
}

interface ExecuteSaasRequestsToolOutputMetadata {
  task_plan: {
    execution_plan: TaskPlanExecutionStep[];
  };
}

const StepIcon: FunctionComponent<{className?: string; step: TaskPlanExecutionStep}> = ({step, className}) => {
  const {findExternalServiceByName} = useExternalService();

  const externalService = useMemo(() => {
    if (!step.code_execution?.external_service_name) {
      return undefined;
    }

    return findExternalServiceByName(step.code_execution.external_service_name);
  }, [findExternalServiceByName, step.code_execution?.external_service_name]);

  return !!step.code_execution?.external_service_name ? (
    <img src={externalService?.icon_url} className={twMerge('rounded-md shrink-0', className)} alt='' />
  ) : step.type === 'CODE' ? (
    <CodeIcon className={twMerge('stroke-accent shrink-0', className)} />
  ) : (
    step.type === 'LLM' && <img src='/favicon.svg' className={twMerge('rounded-md shrink-0', className)} alt='' />
  );
};

const ExecuteSaasRequestsToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const toolArguments = useMemo(() => {
    return castJson<ExecuteSaasRequestsToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  const task_plan = useMemo(() => {
    const output_metadata = toolCall.output_metadata;
    if (!!output_metadata) {
      return (output_metadata as unknown as ExecuteSaasRequestsToolOutputMetadata).task_plan;
    } else {
      return null;
    }
  }, [toolCall]);

  return (
    <div>
      <ToolUsedItemWrapper>
        <div className='flex items-center gap-2'>{toolArguments && <span>{toolArguments.request}</span>}</div>
      </ToolUsedItemWrapper>
      <div className='pl-6'>
        {task_plan &&
          task_plan.execution_plan.map((execution_plan, index) => (
            <div className='mt-4'>
              <div className='flex items-center'>
                <StepIcon step={execution_plan} className='size-4 mr-4' />
                {execution_plan.short_description}
              </div>
              {execution_plan?.code_execution?.code && <PythonCodeMarkdown code={execution_plan.code_execution.code} />}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExecuteSaasRequestsToolUsedItem;
