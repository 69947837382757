import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {QuestionMarkCircledIcon} from '@radix-ui/react-icons';
import * as Tooltip from '@radix-ui/react-tooltip';
import {KeyPrefix, ParseKeysByKeyPrefix} from 'i18next';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ChatModel} from 'scout-chat/types.ts';

export interface ModelDropdownItemProps {
  chatModel: ChatModel;
  tooltipIsOpen: boolean;
  toggleTooltipIsOpen: (modelId: string, IsOpen: boolean) => void;
}

export const ModelDropdownItem: FunctionComponent<ModelDropdownItemProps> = ({
  chatModel,
  tooltipIsOpen,
  toggleTooltipIsOpen,
}) => {
  const handleMobileTooltipPress = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      toggleTooltipIsOpen(chatModel.id, !tooltipIsOpen);
    },
    [toggleTooltipIsOpen, chatModel.id, tooltipIsOpen],
  );

  const handleOnOpenChange = useCallback(
    (open: boolean) => {
      toggleTooltipIsOpen(chatModel.id, open);
    },
    [chatModel.id, toggleTooltipIsOpen],
  );

  return (
    <DropdownMenu.RadioItem value={chatModel.id} className='outline-none'>
      <Tooltip.Provider delayDuration={200}>
        <Tooltip.Root open={tooltipIsOpen} onOpenChange={handleOnOpenChange}>
          <Tooltip.Trigger asChild>
            <div className='flex items-center gap-4 p-2 rounded-md cursor-pointer group/model-select-item hover:bg-surface-03 transition-colors'>
              <div className='flex items-center grow w-[240px]'>
                <div className='flex gap-1 flex-row items-center'>
                  <div className='flex flex-col gap-2'>
                    <div className='font-semibold'>{chatModel.displayName}</div>
                    {chatModel.tags?.length && (
                      <div className='flex flex-wrap gap-2'>
                        {chatModel.tags?.map(tag => <ModelTagView tag={tag} key={tag} />)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={handleMobileTooltipPress}
                className={`md:hidden p-1 rounded-full flex items-center justify-center ${tooltipIsOpen ? 'bg-accent' : ''}`}
              >
                <QuestionMarkCircledIcon className={`${tooltipIsOpen ? 'text-accent-inverse' : ''}`} />
              </div>
              <div className='relative size-6 shrink-0 border-secondary border-solid border-2 rounded-full overflow-hidden p-1'>
                <DropdownMenu.ItemIndicator>
                  <div className='bg-accent size-full rounded-full overflow-hidden' />
                </DropdownMenu.ItemIndicator>
              </div>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <div>
              <div className='md:hidden'>
                <Tooltip.Content
                  className='max-w-[250px] data-[side=top]:animate-slideDownAndFade data-[side=bottom]:animate-slideUpAndFade bg-surface-03 border border-solid border-stroke-main text-primary select-none rounded-md p-4 will-change-[transform,opacity] z-50'
                  sideOffset={2}
                  side='bottom'
                >
                  {chatModel.description}
                  <Tooltip.Arrow className='fill-surface-03' />
                </Tooltip.Content>
              </div>
              <div className='hidden md:block'>
                <Tooltip.Content
                  className='max-w-[250px] animate-slideLeftAndFade bg-surface-02 border border-solid border-stroke-main text-primary select-none rounded-md p-4 will-change-[transform,opacity] z-50'
                  sideOffset={10}
                  side='right'
                >
                  {chatModel.description}
                  <Tooltip.Arrow className='fill-surface-03' />
                </Tooltip.Content>
              </div>
            </div>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </DropdownMenu.RadioItem>
  );
};

export type ModelTag = ParseKeysByKeyPrefix<KeyPrefix<'translation'>, 'chat-models.tags'>;

export const ModelTagView: FunctionComponent<{tag: string}> = ({tag}) => {
  const {t} = useTranslation();

  const translateStr = `chat-models.tags.${tag}` as `chat-models.tags.${ModelTag}`;

  return (
    <span className='text-secondary font-bold text-2xs uppercase px-2 py-1 bg-surface-03 rounded-2xl group-hover/model-select-item:bg-surface-02'>
      {t(translateStr)}
    </span>
  );
};
