import {createContext, useContext} from 'react';

export interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  picture_url?: string;
  isAdmin?: boolean;
}

export interface AuthContextType {
  user: User | null;
  refreshUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
