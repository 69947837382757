import React, {useCallback, useEffect, useState} from 'react';
import {AuthContext, User} from 'scout-chat/hooks/contexts/auth/use-auth.tsx';
import {getTokenPayload} from 'scout-chat/repositories/tokens_repository.ts';

export const AuthProvider: React.FC<Pick<React.HTMLProps<HTMLElement>, 'children'>> = ({children}) => {
  const [user, setUser] = useState<User | null>(null);

  const refreshUser = useCallback(async () => {
    const payload = await getTokenPayload();
    if (!payload) {
      throw new Error('Invalid token');
    }

    const user: User = {
      id: payload.sub,
      firstName: payload.first_name,
      lastName: payload.last_name,
      picture_url: payload.picture_url,
      isAdmin: payload.is_admin,
    };

    setUser(user);
  }, []);

  useEffect(() => {
    try {
      refreshUser();
    } catch {}
  }, [refreshUser]);

  return <AuthContext.Provider value={{user, refreshUser}}>{children}</AuthContext.Provider>;
};
