import {fetchUsageStatistics} from '#/repositories/assistants-api/fetch-usage-statistics';
import {useQuery} from '@tanstack/react-query';

export const useUsageStatisticsQuery = () =>
  useQuery({
    queryKey: ['usage-statistics'],
    queryFn: async () => {
      const response = await fetchUsageStatistics();
      return response.data;
    },
  });
