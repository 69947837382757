import {RunVariables} from '#/repositories/assistants-api/requests/fetch-task-run';
import {FileIcon} from '@radix-ui/react-icons';
import {FunctionComponent} from 'react';
import ProtectedAnchor from 'scout-chat/components/ProtectedAnchor.tsx';
import ProtectedImg from 'scout-chat/components/ProtectedImg.tsx';

const is_link = (value: string) => value.startsWith('http') || value.startsWith('https');
const is_protected_image = (value: string) => value.startsWith('/protected') || value.endsWith('.webp');

const object_key_to_display = (key: string) => key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

const variable_to_links = (links: Record<string, string>) => {
  const values = Object.values(links);
  const keys = Object.keys(links);

  return (
    <div>
      {values.map(link => {
        const display_name = link.startsWith('http') ? keys[values.indexOf(link)] : link;
        const href = link.startsWith('http') ? link : keys[values.indexOf(link)];

        return (
          <div key={link}>
            <ProtectedAnchor href={href} className='truncate'>
              <div className='flex items-center border rounded-md p-1 w-fit hover:opacity-70'>
                <FileIcon className='size-5 mr-2' />
                <span className='truncate w-full text-sm'>{display_name}</span>
              </div>
            </ProtectedAnchor>
          </div>
        );
      })}
    </div>
  );
};

const variable_to_component = (value: string | number | boolean | object) => {
  if (value === null) return;
  if (typeof value === 'string') {
    if (is_protected_image(value)) {
      return (
        <ProtectedImg
          animate={true}
          className='rounded-md'
          containerClassName='w-[400px] mt-2 aspect-square'
          src={value}
        />
      );
    } else if (is_link(value)) {
      return (
        <a href={value} target='_blank' rel='noreferrer' className='truncate'>
          <span className='truncate w-full'>{value}</span>
        </a>
      );
    } else {
      return <div>{value.substring(0, 200)}</div>;
    }
  } else if (typeof value !== 'number' && typeof value !== 'boolean') {
    if (!!(value as []).filter) {
      return (
        <div>
          {(value as []).slice(0, 10).map((a: string | number | boolean, index) => {
            return <div key={index}>{variable_to_component(a)}</div>;
          })}
        </div>
      );
    } else {
      return (
        <div>
          {Object.keys(value).map((key, index) => {
            return (
              <div className='mr-4 mt-2' key={index}>
                <div className='font-bold'>{object_key_to_display(key)}</div>
                <div className='ml-2'>
                  {variable_to_component(
                    (value as Record<string, string | number | boolean | object>)[key] as unknown as
                      | string
                      | number
                      | boolean
                      | object,
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  } else {
    return <div>{value}</div>;
  }
};

export const TaskRunLogVariables: FunctionComponent<{variables: RunVariables}> = props => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(props.variables, null, 2));
  };

  return (
    <div onClick={handleCopyToClipboard} className='mt-2'>
      {props.variables &&
        Object.keys(props.variables).map((variable, index) => (
          <div className='mr-4 overflow-hidden' key={index}>
            <div className='font-bold'>
              {variable !== 'final_result' && variable !== 'created_files' ? object_key_to_display(variable) : ''}
            </div>
            <div className='mx-2'>
              {variable === 'created_files'
                ? variable_to_links(props.variables[variable] as unknown as Record<string, string>)
                : variable !== 'final_result' || !!!props.variables['created_files']
                  ? variable_to_component(props.variables[variable])
                  : null}
            </div>
          </div>
        ))}
    </div>
  );
};
